import { useCallback, useEffect, useState, useLayoutEffect } from 'react'
import debounce from 'lodash/debounce'

// should match _breakpoint.scss
const sizes = {
  xxxs: 320,
  xxs: 375,
  xs: 480,
  client_xs: 550,
  sm: 768,
  mm: 1440,
  md: 1024,
  xl: 1440,
} as const

type BPSize = keyof typeof sizes

const useIsMobile = (breakSize: BPSize): boolean => {
  const [isMobile, setIsMobile] = useState(false)

  const shouldBeMobile = (breakSize: BPSize): boolean => {
    const width = window.outerWidth;
    return width <= sizes[breakSize];
  }

  const handleWindowResize = useCallback(() => {
    setIsMobile(shouldBeMobile(breakSize))
  }, [breakSize])

  useLayoutEffect(() => {
    // Set state on mount.
    handleWindowResize()
  }, [handleWindowResize])

  useEffect(() => {
    const debouncedResize = debounce(handleWindowResize, 200, { leading: true })
    window.addEventListener('resize', debouncedResize)
    return () => {
      window.removeEventListener('resize', debouncedResize)
    }
  }, [handleWindowResize])
  
  return isMobile
}

export default useIsMobile