import { RouteComponentProps } from 'react-router'
import MainLayout from '@/shared/components/Layout/MainLayout'
import ContactsTable from '@/features/client/components/ContactsTable'
import MessagesTable from '@/features/client/components/MessagesTable'
import CreateMessageModal from '@/features/messages/components/CreateMessageModal'
import { useState, useEffect, useCallback } from 'react'
import PageHeader from '@/shared/components/PageHeader'
import useAccountName from '@/features/auth/hooks/useAccountName'
import ClientDocuments from '@/features/client/components/ClientDocuments'
import TabList from '@/shared/components/Tabs/TabList'
import Tab from '@/shared/components/Tabs/Tab'
import TabPanel from '@/shared/components/Tabs/TabPanel'
import useQueryParamSubscription from '@/shared/hooks/useQueryParamSubscription'
import { useGetBoxClientRootFolderQuery, useGetBoxTokenQuery } from '@/shared/api/services/boxServices'
import { useGetEmployeeClientNavbarRulesQuery } from '@/shared/api/services/legacyService'
import { DefaultButton, MessageBarType, PrimaryButton } from '@fluentui/react'
import { BoxStateType } from '@/shared/types/box'
import { useGetContactsByAccountIdQuery } from '@/shared/api/services/crmService'
import { useDispatch } from 'react-redux'
import { appDispatch } from '@/bootstrap/redux'
import { createUIMessage } from '@/features/uimessages/redux/operations'
import ClientSettings from '@/features/client/components/ClientSettings'
import { getSharedDocumentLink } from '../SharedDocumentLink/SharedDocumentLink'
import ClientAdmin from '@/features/client/components/ClientAdmin'
import { TabsMobile } from '@/shared/components'
import useIsMobile from '@/shared/hooks/useIsMobile'

export type TabKey = 'users' | 'docs' | 'messages' | 'settings' | 'admin'
type TabData = { tabId: string, panelId: string, label: string }
const TABS: Array<[TabKey, TabData]> = [
  ['users', { tabId: 'users-tab', panelId: 'users-panel', label: 'Users' }],
  ['docs', { tabId: 'docs-tab', panelId: 'docs-panel', label: 'Documents' }],
  ['messages', { tabId: 'messages-tab', panelId: 'messages-panel', label: 'Messages' }],
  ['settings', { tabId: 'settings-tab', panelId: 'settings-panel', label: 'Settings' }],
  ['admin', { tabId: 'admin-tab', panelId: 'settings-panel', label: 'Admin' }],
]

export default function Client(props: RouteComponentProps<{ accountid: string }>) {
  const { match } = props
  const { accountid } = match.params
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { accountName } = useAccountName(accountid)
  const [selectedPanel, setSelectedPanel] = useQueryParamSubscription<TabKey>('tab', 'users')
  const { data: rootFolderId } = useGetBoxClientRootFolderQuery(accountid)
  const { data: navbarRules, ...navbarRulesQS } = useGetEmployeeClientNavbarRulesQuery(accountid)
  const { data: boxData, isLoading: boxIsLoading, isUninitialized: boxIsUninitialized, isError: boxIsError, refetch: boxRefetch } = useGetBoxTokenQuery(accountid)
  const [boxAccess, setBoxAccess] = useState<BoxStateType>({ token: '', folder: '' })
  const contactsQueryState = useGetContactsByAccountIdQuery(accountid)
  useEffect(() => {
    if (!boxIsLoading || !boxIsUninitialized) {
      setBoxAccess({ token: boxData?.accessToken, folder: rootFolderId })
    }
  }, [boxIsLoading, boxIsUninitialized])

  const showDocs = !navbarRulesQS.isError && navbarRules?.documents
  const showSettings = !navbarRulesQS.isError && navbarRules?.settings
  const showAdmin = !navbarRulesQS.isError && navbarRules?.admin

  const [folderId] = useQueryParamSubscription('folderId')
  const [fileId] = useQueryParamSubscription('fileId')
  const buttonText = fileId ? 'Copy link to file' : 'Copy link to folder'
  const [tab, setTab] = useQueryParamSubscription('tab')
  const dispatch = useDispatch<appDispatch>()
  const [type, setType] = useState('');
  const [name, setName] = useState('')
  
  const isMobile375 = useIsMobile('xs')
  const isMobile550 = useIsMobile('client_xs')
  const isMobile320 = useIsMobile('xxxs')

  const determineTabVisibility = (isMobile375, isMobile320, isMobile550) => {
    if (isMobile320) {
      return 1;
    }
    if (isMobile375) {
      return 2;
    }
    if (isMobile550) {
      return 3;
    }
    return 0;
  }
  

  const cuttingValue = determineTabVisibility(isMobile375, isMobile320, isMobile550)
  const visibleTabs = cuttingValue ? TABS.slice(0, cuttingValue) : TABS;
  const dropdownTabs = cuttingValue ? TABS.slice(cuttingValue) : null;

  function getPanelContentByKey(key: TabKey) {
    if (key === 'docs' && showDocs) {
      return <ClientDocuments accountid={accountid} rootFolderId={rootFolderId} label={`${accountName} Document Explorer`} setFileInfo={getFileOrFolderName} />
    }

    if (key === 'users') {
      return <ContactsTable accountid={accountid} />
    }

    if (key === 'messages') {
      return <MessagesTable accountid={accountid} />
    }

    if (key === 'settings' && showSettings) {
      return <ClientSettings accountId={accountid} headerLabel={`${accountName} Settings`} />
    }

    if (key === 'admin' && showAdmin) {
      return <ClientAdmin accountId={accountid} accountName={accountName} headerLabel={`${accountName} Admin`} />
    }
  }

  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  const handleCopyClick = useCallback(async () => {
    try {
      const { location } = window
      const { href } = location
      const url = new URL(href)
      const { searchParams } = url
      const folderIdParam = searchParams.get('folderId')
      const fileIdParam = searchParams.get('fileId')
      await navigator.clipboard.writeText(getSharedDocumentLink(accountid, folderIdParam, fileIdParam))
      dispatch(createUIMessage({ 
        key: 'boxfoliderclipboard', 
        content: `Successfully copied a link to ${type} <b>${name}</b> to your clipboard`,
        messageBarType: MessageBarType.success
      }))
    } catch (error) {
      console.error(error)
    }
  }, [name, type, dispatch])

  const getFileOrFolderName = (type: string, name: string) => {
    setType(type)
    setName(name)
  }

  const copyLinkButton = (accountName && tab === 'docs') ? (
    <DefaultButton text={buttonText} onClick={handleCopyClick} />
  ) : null
  const sendMsgButton = <PrimaryButton onClick={handleClick}>Send Message</PrimaryButton>
  const action = [copyLinkButton, sendMsgButton]

  return (
    <MainLayout className='c-client c-page' id="client">
      <PageHeader label={accountName} action={action} />
      <CreateMessageModal isOpen={isOpen} setIsOpen={setIsOpen} accountid={accountid} boxAccess={boxAccess} contacts={contactsQueryState} />
      <TabList>
        {visibleTabs.map(([key, tab]) => {
          if (key === 'docs' && !showDocs) {
           return null
          }
          if (key === 'settings' && !showSettings) {
            return null
          }
          if (key === 'admin' && !showAdmin) {
            return null
          }
          return (
            <Tab
              key={key}
              id={tab.tabId}
              panelId={tab.panelId}
              isSelected={selectedPanel === key}
              label={tab.label}
              onClick={() => setSelectedPanel(key)}
            />
          )
        })}
        { dropdownTabs ? <TabsMobile childrenTab={dropdownTabs} onSelect={ setSelectedPanel } selectedKey={selectedPanel}></TabsMobile> : null }
      </TabList>
      {TABS.map(([key, tab]) => (
        <TabPanel key={key} id={tab.panelId} tabId={tab.tabId} isActive={selectedPanel === key}>
          {getPanelContentByKey(key)}
        </TabPanel>
      ))}
    </MainLayout>
  )
}
