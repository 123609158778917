import React from 'react';

const LogoCambridge = (props: React.SVGAttributes<SVGElement>) => (
  <svg viewBox="0 0 256.3 81.8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <style type="text/css">
      {`
        .st0{fill:#A8AAAD;}
        .st1{fill:#FFFFFF;}
        .st2{fill:#223E7D;}
        .st3{fill:#223E7D;stroke:#223E7D;stroke-width:0.75;stroke-miterlimit:10;
        }
      `}
      
    </style>
    <g>
      <rect className="st0" width="81.8" height="81.8"  />
      <path
        className="st1"
        d="M14.7,50.3c-3.1-2.8-3.9-6.5-3.9-9.7c0-2.2,0.7-6.1,3.7-9.1c2.3-2.2,5.3-3.5,10-3.5c2,0,3.1,0.1,4.6,0.3
		c1.2,0.2,2.2,0.5,3.2,0.6c0.3,0,0.5,0.2,0.5,0.4c0,0.3-0.1,0.7-0.2,1.8c-0.1,1.1,0,2.9-0.1,3.6c0,0.5-0.1,0.8-0.4,0.8
		c-0.3,0-0.3-0.3-0.3-0.7c0-1-0.4-2.2-1.2-3c-1-1.2-3.5-2-6.4-2c-2.8,0-4.6,0.7-6,2c-2.3,2.2-2.9,5.4-2.9,8.6
		c0,7.9,5.7,11.8,10,11.8c2.8,0,4.5-0.3,5.8-1.8c0.5-0.6,1-1.6,1.1-2.2c0.1-0.5,0.2-0.7,0.4-0.7c0.2,0,0.3,0.2,0.3,0.5
		c0,0.4-0.4,3.3-0.7,4.4c-0.2,0.6-0.3,0.7-0.8,1c-1.3,0.5-3.7,0.8-5.7,0.8C21.3,53.9,17.6,52.9,14.7,50.3z"
      />
      <path
        className="st1"
        d="M54.9,44.9c-0.2,0-0.2,0.1-0.3,0.3l-1.8,4.7c-0.3,0.8-0.5,1.6-0.5,2c0,0.6,0.3,1,1.3,1h0.5
		c0.4,0,0.5,0.1,0.5,0.3c0,0.3-0.2,0.3-0.6,0.3c-1.1,0-2.5-0.1-3.5-0.1c-0.4,0-2.2,0.1-3.9,0.1c-0.4,0-0.6-0.1-0.6-0.3
		c0-0.2,0.1-0.3,0.4-0.3c0.3,0,0.8,0,1-0.1c1.5-0.2,2.1-1.3,2.8-3l8.3-20.7c0.4-1,0.6-1.3,0.9-1.3c0.3,0,0.5,0.3,0.8,1.1
		c0.8,1.8,6.1,15.3,8.2,20.3c1.3,3,2.2,3.4,2.9,3.6c0.5,0.1,1,0.1,1.4,0.1c0.3,0,0.4,0,0.4,0.3c0,0.3-0.3,0.3-1.5,0.3
		c-1.2,0-3.6,0-6.2-0.1c-0.6,0-1,0-1-0.3c0-0.2,0.1-0.3,0.5-0.3c0.2-0.1,0.5-0.4,0.3-0.8l-2.6-7c-0.1-0.2-0.2-0.2-0.3-0.2H54.9z
		 M61.6,43.2c0.2,0,0.2-0.1,0.2-0.2l-3-8.2c0-0.1-0.1-0.3-0.2-0.3c-0.1,0-0.2,0.2-0.2,0.3l-3,8.1c0,0.1,0,0.2,0.1,0.2H61.6z"
      />
      <g>
        <path
          className="st2"
          d="M109,23.3c1.5,0,3.1,0.5,4.5,1.5l-1.3,1.7c-1-0.8-2-1.1-3.1-1.1c-2.3,0-3.9,1.6-3.9,3.9
			c0,2.5,1.6,4.2,3.9,4.2c1.3,0,2.3-0.4,3.2-1.3l1.5,1.5c-1.3,1.2-2.9,1.8-4.7,1.8c-3.9,0-6.3-2.6-6.3-6.2
			C102.7,25.8,105.2,23.3,109,23.3z"
        />
        <path
          className="st2"
          d="M122.1,23.5h2.7l4.7,11.7h-2.6l-0.9-2.3h-5.2l-0.9,2.3h-2.6L122.1,23.5z M121.5,31.1h3.8l-1.9-5h0L121.5,31.1
			z"
        />
        <path
          className="st2"
          d="M133.7,23.5h2.4l4,5.2l4-5.2h2.3v11.7h-2.4V27l-4,5.1l-4-5.1v8.1h-2.4V23.5z"
        />
        <path
          className="st2"
          d="M152.6,23.5h5.2c3,0,4.4,1.3,4.4,3.1c0,1.2-0.7,2-1.7,2.5c1.4,0.4,2.2,1.4,2.2,2.7c0,2.2-1.8,3.4-4.6,3.4
			h-5.5V23.5z M157.7,28.5c1.4,0,2.1-0.5,2.1-1.5c0-1-0.7-1.5-2.1-1.5H155v3H157.7z M158.1,33.3c1.4,0,2.1-0.5,2.1-1.6
			c0-1.1-0.8-1.6-2.1-1.6h-3v3.2H158.1z"
        />
        <path
          className="st2"
          d="M167.8,23.5h5.5c3.4,0,4.6,2,4.6,3.8c0,1.3-0.6,2.7-2.2,3.3l2.4,4.5h-2.6l-2-4.1h-0.1h-3.1v4.1h-2.5V23.5z
			 M173.3,29.2c1.6,0,2.2-0.9,2.2-1.8c0-1-0.6-1.8-2.2-1.8h-3v3.7H173.3z"
        />
        <path className="st2" d="M184.1,23.5h2.5v11.7h-2.5V23.5z" />
        <path
          className="st2"
          d="M192.3,23.5h4c4.4,0,6.7,2.2,6.7,5.7c0,3.7-2.2,6-6.7,6h-4V23.5z M196.3,33.2c2.9,0,4.2-1.5,4.2-4
			c0-2.3-1.4-3.7-4.2-3.7h-1.6v7.7H196.3z"
        />
        <path
          className="st2"
          d="M212.8,23.3c1.5,0,3.1,0.5,4.5,1.3l-1.2,1.7c-1-0.6-2.1-0.9-3.3-0.9c-2.4,0-4,1.7-4,4c0,2.4,1.5,4.2,4,4.2
			c1,0,1.9-0.2,2.5-0.5v-2.5h-2.9v-1.7h5.1v5.3c-1.3,0.9-3,1.4-4.7,1.4c-3.9,0-6.4-2.5-6.4-6.2C206.4,25.8,208.9,23.3,212.8,23.3z"
        />
        <path
          className="st2"
          d="M223,23.5h8.9v2h-6.5v2.8h5.8v1.8h-5.8v3h6.6v2h-9V23.5z"
        />
        <path
          className="st2"
          d="M106.8,46.5h2.7l4.7,11.7h-2.6l-0.9-2.3h-5.2l-0.9,2.3h-2.6L106.8,46.5z M106.3,54.1h3.8l-1.9-5h0L106.3,54.1
			z"
        />
        <path
          className="st2"
          d="M118.5,55.3c1,0.7,2.4,1.2,3.7,1.2c1.7,0,2.4-0.6,2.4-1.4c0-2.5-7-0.9-7-5.3c0-2,1.7-3.5,4.7-3.5
			c1.6,0,3.1,0.4,4.4,1l-1,1.8c-1-0.5-2.4-0.8-3.4-0.8c-1.4,0-2.2,0.6-2.2,1.4c0,2.5,7,0.7,7,5.4c0,2-1.6,3.5-4.8,3.5
			c-1.8,0-3.7-0.6-4.9-1.4L118.5,55.3z"
        />
        <path
          className="st2"
          d="M132,55.3c1,0.7,2.4,1.2,3.7,1.2c1.7,0,2.4-0.6,2.4-1.4c0-2.5-7-0.9-7-5.3c0-2,1.7-3.5,4.7-3.5
			c1.6,0,3.1,0.4,4.4,1l-1,1.8c-1-0.5-2.4-0.8-3.4-0.8c-1.4,0-2.2,0.6-2.2,1.4c0,2.5,7,0.7,7,5.4c0,2-1.6,3.5-4.8,3.5
			c-1.8,0-3.7-0.6-4.9-1.4L132,55.3z"
        />
        <path
          className="st2"
          d="M149.8,46.3c3.4,0,6.3,2.5,6.3,6c0,3.8-2.8,6.2-6.3,6.2c-3.5,0-6.3-2.4-6.3-6.2
			C143.6,48.7,146.4,46.3,149.8,46.3z M149.8,56.5c2.1,0,3.8-1.7,3.8-4.2c0-2.3-1.7-4-3.8-4c-2.1,0-3.8,1.7-3.8,4
			C146,54.8,147.7,56.5,149.8,56.5z"
        />
        <path
          className="st2"
          d="M165.6,46.3c1.5,0,3.1,0.5,4.5,1.5l-1.3,1.7c-1-0.8-2-1.1-3.1-1.1c-2.3,0-3.9,1.6-3.9,3.9
			c0,2.5,1.6,4.2,3.9,4.2c1.3,0,2.3-0.4,3.2-1.3l1.5,1.5c-1.3,1.2-2.9,1.8-4.7,1.8c-3.9,0-6.3-2.6-6.3-6.2
			C159.3,48.8,161.8,46.3,165.6,46.3z"
        />
        <path className="st2" d="M174.5,46.5h2.5v11.7h-2.5V46.5z" />
        <path
          className="st2"
          d="M185.7,46.5h2.7l4.7,11.7h-2.6l-0.9-2.3h-5.2l-0.9,2.3H181L185.7,46.5z M185.1,54.1h3.8l-1.9-5h0L185.1,54.1z
			"
        />
        <path
          className="st2"
          d="M199,48.6h-4.2v-2.1h10.9v2.1h-4.2v9.6H199V48.6z"
        />
        <path
          className="st2"
          d="M209.7,46.5h8.9v2h-6.5v2.8h5.8v1.8h-5.8v3h6.6v2h-9V46.5z"
        />
        <path
          className="st2"
          d="M224,55.3c1,0.7,2.4,1.2,3.7,1.2c1.7,0,2.4-0.6,2.4-1.4c0-2.5-7-0.9-7-5.3c0-2,1.7-3.5,4.7-3.5
			c1.6,0,3.1,0.4,4.4,1l-1,1.8c-1-0.5-2.4-0.8-3.4-0.8c-1.4,0-2.2,0.6-2.2,1.4c0,2.5,7,0.7,7,5.4c0,2-1.6,3.5-4.9,3.5
			c-1.8,0-3.7-0.6-4.9-1.4L224,55.3z"
        />
      </g>
      <g>
        <line className="st3" x1="40.9" y1="15.3" x2="40.9" y2="66.5" />
      </g>
    </g>
  </svg>
);

export default LogoCambridge;