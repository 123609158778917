import { memo } from 'react'
import { LogoCA, LogoFull } from '@/shared/components/Logo'
import { Link } from '@fluentui/react'
import { useTranslation } from 'react-i18next'
import LogoCambridge from '../Logo/LogoCambridge'

const privacyPolicyURL = 'https://www.cambridgeassociates.com/privacy-policy/'
const termsOfUseURL = '/doc/CA-Website-User-Agmt-Feb-2024.pdf'

function Footer() {
  const { t } = useTranslation('footer')

  return (
    <footer className="c-footer">
      <section className="c-footer-logo">
        <LogoCambridge height="60px" />
      </section>
      <section className="c-footer-legal">
        <span className="c-footer-legal__item"><small>&copy; {new Date().getFullYear()} Cambridge Associates</small></span>
        <span className="c-footer-legal__item"><small>{t('legal')}</small></span>
      </section>
      <section className="c-footer-links">
        <Link href={privacyPolicyURL} target='_blank'>{t('link1')}</Link>
        <Link href={termsOfUseURL} target='_blank' download>{t('link2')}</Link>
      </section>
    </footer>
  )
}

export default memo(Footer)