import { TabKey } from '@/screens/Client/Client';
import { TabPortfolioKey } from '@/screens/ClientPortfolio/ClientPortfolio';
import { IDropdownOption, Dropdown } from '@fluentui/react';
import { memo } from 'react';

type TabData = { tabId: string, panelId: string, label: string }

type TabsMobileProps = {
  childrenTab?: Array<[TabKey | TabPortfolioKey, TabData]>,
  selectedKey?: string,
  onSelect?: (query: string) => void
}

const TabsMobile = ({
  childrenTab,
  selectedKey,
  onSelect,
}: TabsMobileProps) => {

  const dropdownOptions: IDropdownOption[] = childrenTab.map(([key, tab]) => ({
    key: key as string,
    text: tab.label,
  }));

  const handleDropdownChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
    if (option) {
      onSelect(option.key as TabKey);
    }
  };

  return (
    <div className="tab-dropdown">
      <Dropdown
        placeholder="More"
        options={dropdownOptions}
        onChange={handleDropdownChange}
        selectedKey={selectedKey}
      />
    </div>
  )
}

export default memo(TabsMobile)