import { useState } from 'react'
import { DefaultButton } from '@fluentui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GridApi } from 'ag-grid-community'
import DonutChart from '@/lib/common/components/charts/donut-chart/DonutChart'
import { Card, Section } from '@/shared/components'
import { ExpandButtonColumn, IDataGridCellStyleParams, PercentageColumn } from '@/lib/common/components/grid/Columns'
import DataGrid, { DataGridInitEvent } from '@/lib/common/components/grid/Grid'
import { formatDate, parseFormattedDate } from '@/lib/common/services/date/DateService'
import UtilService from '@/lib/common/services/util/UtilService'
import { IPortfolioSettings, SLEEVE_DISPLAY_TYPE, exportGridData, getGridExcelStyles, useGetPortfolioAllocation } from '@/shared/api/services/portfolioService'
import { DashboardSettings } from '@/features/client/components/ClientSettings/ClientSettings.model'
import SkeletonClientPortfolioAllocation from '../../Skeletons/SkeletonClientPortfolioAllocation'
import AllocationDetail from './AllocationDetail/AllocationDetail'
import { PerformanceSleeveColumn } from '../../../Components/Grid/Column/PerformanceSleeveColumn'
import ReturnToTotalPortfolioButton from '../../TotalPortfolioButton/ReturnToTotalPortfolio'
import PerformanceGridFootNote from '@/screens/ClientPortfolio/Components/Grid/FootNote/PerformanceGridFootNote'

import './AllocationMobile.scss'

export interface AllocationMobileProps {
  portfolioId: string,
  asOfDate: string,
  sleeveId?: string,
  portfolioSettings: IPortfolioSettings,
  dashboardSettings: DashboardSettings,
  previewAccountId?: string,
  setShowTotalPortfolioButton?: (showTotalPortfolioButton: boolean) => void,
  setTotalPortfolioId?: (totalPortfolioId: string) => void,
}

export default function AllocationMobile({
  portfolioId,
  asOfDate,
  sleeveId,
  portfolioSettings,
  dashboardSettings,
  previewAccountId,
  setShowTotalPortfolioButton,
  setTotalPortfolioId,
}: AllocationMobileProps) {
  const [isDetailed, setIsDetailed] = useState<boolean>(false)
  const [indexSelected, setIndexSelected] = useState<number>(0)
  const { data, isFetching } = useGetPortfolioAllocation({ portfolioId, asOfDate, previewAccountId })
  const [gridApi, setGridApi] = useState(null as GridApi)
  let allocationTemplateGrid = []
  let allocationTemplateChart = []
  const parsedSleeveId = !!sleeveId ? Number(sleeveId) : 0
  const result = (data || [])

  let totalAssets;
  const firstOption = result.find(p => {
    if (p.Lvl === 0) {
      totalAssets = p
    }
    
    if (parsedSleeveId) {
      return (p.PLISk === parsedSleeveId) && (p.DisplayPLIType === SLEEVE_DISPLAY_TYPE)
    }


    return (p.Lvl === 0) && (p.DisplayPLIType === SLEEVE_DISPLAY_TYPE)
  })
  const options = [
    firstOption,
    ...result.filter(p => {
      return (p.DisplayPLIType === SLEEVE_DISPLAY_TYPE) && (p.ParentPLISk === firstOption?.PLISk)
    })
  ].filter(option => !!option)

  if (!isFetching) {
    allocationTemplateGrid = options.map((row, i) => {
      return {
        ...row,
        name: row.Name,
        value: row?.MV,
        total: row?.MV,
        color: row?.Color,
        currentMktValue: row?.MV,
        actualAllocation: row?.Alloc,
        longTrmTrgt: row?.TgtAllocLT,
        longTrmTrgtVariance: row?.TgtVarPct,
        currencySymbol: row?.CurrencySymbol,
        level: row?.Lvl,
        sleeveId: row?.id,
      }
    })
    allocationTemplateChart = allocationTemplateGrid.filter(row => {
      return row.level === 1
    })
  }

  const isFootNoteHidden = !(allocationTemplateGrid || []).some(r => !!r.StatusAggregate)
  const parsedAsOfDate = parseFormattedDate(asOfDate)
  const subtitle = `As of ${formatDate(parsedAsOfDate, 'MMM dd, yyyy')}`
  
  const getInvestmentCellStyle = (params: IDataGridCellStyleParams) => {
    const data = params?.data || {}
    const styles = {} as any

    styles['boxShadow'] = `5px 0px ${data.color} inset`
    styles['boxSizing'] = 'border-box !important'

    if (params.rowIndex <= 0) {
      styles['background-color'] = `${data.color}1F`
    }
    
    return styles
  }
  const getRowColor = (params: IDataGridCellStyleParams) => {
    const data = params?.data || {}
    const styles = {} as any
    
    if (params.rowIndex <= 0) {
      styles['background-color'] = `${data.color}1F`
    }
    
    return styles
  }

  const getRowStyle = (params: IDataGridCellStyleParams) => {
    const data = params?.data || {}
    const styles = {} as any

    if (params.rowIndex <= 0) {
      styles['background-color'] = `${data.color}1F`
    }
    
    if(data?.name === 'Total Assets') {
      styles['font-weight'] = '600'
    }
    
    return styles
  }

  const getCellClass = (params: IDataGridCellStyleParams) => {
    if (params.rowIndex !== 0) {
      return 'c-allocation-tab'
    }
    
    return ''
  }

  const onClick = (event) => {
    if (event.rowIndex > 0) {
      setIndexSelected(event.rowIndex - 1)
      setIsDetailed(true)
    }
  }

  const onSeriesClick = (event) => {
    const index = allocationTemplateChart.indexOf(event)
    if (index !== -1) {
      setIndexSelected(index)
      setTimeout(() => setIsDetailed(true), 150)
    }
  }

  if (isDetailed) {
    return (
      <AllocationDetail indexSelected={indexSelected} asOfDate={subtitle} allocationData={allocationTemplateChart} onClose={(e) => setIsDetailed(e)}></AllocationDetail>
    )
  }

  const onGridInit = (event: DataGridInitEvent) => {
    const { gridApi } = event
    setGridApi(gridApi)
  }

  const handleExcelDownloadClick = () => {
    exportGridData({
      gridApi,
      excelTabName: 'Allocation',
      fileName: `allocation-export-investment-pool-${portfolioSettings?.investmentPoolName}-${portfolioSettings?.investmentPoolId}-as-of-date-${asOfDate}`,
    })
  }

  const showTotalAssetsButton = () => {
    const { id } = totalAssets || {} as any

    setShowTotalPortfolioButton(false)

    if (sleeveId && totalAssets?.id != sleeveId) {
      setShowTotalPortfolioButton(true)
      setTotalPortfolioId(id)

      return (
        <div key={0}>
          <ReturnToTotalPortfolioButton portfolioId={portfolioId} asOfDate={asOfDate} id={id} previewAccountId={previewAccountId} />
        </div>
      )
    }

    return null
  }

  return (
    <>
      {(dashboardSettings && dashboardSettings.enablePortfolioDashboardAllocation) ? (
        <Card className='c-onboarding-welcome__right__card card-left-min card-mobile-right' ariaLabel='Welcome'>
          <Section className='c-portfolio-section' title='Allocation' subtitle={subtitle} actions={[
            <div className='button-container' key={0}>
              {showTotalAssetsButton()}
              <div key='downloadBtn' className='download-button-container'>
                <DefaultButton className='download-button' onClick={handleExcelDownloadClick}>
                  <FontAwesomeIcon icon='download' className='c-sidebar-section__heading-icon' />
                </DefaultButton>
              </div>
            </div>
          ]}>
            {
              isFetching ? <SkeletonClientPortfolioAllocation /> : (
                <div className='c-portfolio-section-allocation-mobile'>
                  <DonutChart
                    series={allocationTemplateChart}
                    legend={{
                      position: 'right'
                    }}
                    responsive={true}
                    centerTextOptions={{
                      showCenterText: true,
                      getCenterText: () => {
                        const maxValue = allocationTemplateGrid?.[0]?.total
                        const currencySymbol = allocationTemplateGrid?.[0]?.currencySymbol || '$'
                        return ({
                          top: '105px',
                          text: currencySymbol + `${UtilService.nFormatter(maxValue, 1)}`,
                          font: {
                            size: '30px',
                            family: 'Segoe UI'
                          }
                        })
                      }

                    }}
                    onSeriesClick={onSeriesClick}
                  />
                  <DataGrid
                    className='c-portfolio-allocation-mobile'
                    showToolBar={false}
                    rows={allocationTemplateGrid}
                    enablePagination={false}
                    domLayout='normal'
                    onInit={onGridInit}
                    excelStyles={getGridExcelStyles({
                      currencySymbol: portfolioSettings?.currency?.symbol,
                      dateFormat: portfolioSettings?.dateFormat,
                    })}
                  >
                    <PerformanceSleeveColumn onCellClicked={onClick} disableFilterColumn={true} title='' field='name' checkboxSelection={false} cellClass={getCellClass} cellStyle={getInvestmentCellStyle} />
                    <PercentageColumn title='Actual Allocation (%)' disableFilterColumn={true}  field='actualAllocation'  headerClass='text-break-right' cellStyle={getRowStyle} width={150} />
                    <ExpandButtonColumn onCellClicked={onClick} title='' field=' ' headerName='allocation-mobile' width={5} checkboxSelection={false} cellStyle={getRowColor} valueGetter={() => ''}/>
                  </DataGrid>
                  <PerformanceGridFootNote additionalClassName='AllocationFootNote' hidden={isFootNoteHidden} showTitle={false} />
                </div>
              )
            }
          </Section>
        </Card>
      ) : null}
    </>
  )
}